import { ComponentRef, ElementRef, Renderer2 } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Constant, FIELD_COMPONENT, MODULE_NAME, UserRoleEnum } from 'app/config/constants';
import { DialogMessageComponent } from 'app/dialog/dialog-message/dialog-message.component';
import { Common } from 'app/model/entity/common';
import { Privilege } from 'app/model/entity/privilege';
import {
  ResetNewsContentTabStateAction,
  ResetOpenWeatherContentTabStateAction,
  ResetWeatherContentTabStateAction
} from 'app/module/external-content-manager/ngrx/content-action';
import { ResetMiniMediaStateAction } from 'app/module/simple-signage-editor/ngrx/content-action';
import {
  ResetBusInfoDisplayEditorStateAction,
  ResetDashboardStateAction,
  ResetDeliveryManagerStateAction,
  ResetDestinationSignEditorStateAction,
  ResetDeviceManagerStateAction,
  ResetDigitalSignageEditorStateAction,
  ResetExternalContentManagerAction,
  ResetIndexWordStateAction,
  ResetLCDEditorStateAction,
  ResetLEDEditorStateAction,
  ResetMasterListStateAction,
  ResetMediaManagerStateAction,
  ResetProjectManagerStateAction,
  ResetRouteListStateAction,
  ResetSignageDisplayEditorAction,
  ResetSimpleSignageEditorStateAction,
  ResetSortFilterLCDLayoutEditorStateAction,
  ResetSortFilterLEDLayoutEditorStateAction,
  ResetSortFilterStateAction,
  ResetSortFilterUserManagerStateAction,
  ResetStationDisplayEditorAction,
  ResetTimetableEditorAction,
  ResetTimetableOperationManagerStateAction,
  ResetUserManagerStateAction,
  SaveMainStateAction
} from 'app/ngrx-component-state-management/component-state.action';
import { DataService } from 'app/service/data.service';
import { DialogService } from 'app/service/dialog.service';
import { AppState } from 'app/store/app.state';
declare let FontFace: any;
export class Helper {
  /**
   * open components
   */
  public static openComponents(commonObject: Common): Array<string> {
    let result = new Array<string>();
    let restore1 = commonObject.fieldComponent1?.split('-');
    let restore2 = commonObject.fieldComponent2?.split('-');
    let restore3 = commonObject.fieldComponent3?.split('-');
    if (restore1 && restore2 && +restore1[0] == +restore2[0]) {
      result.push(Constant.FIELD_COMPONENT_2);
    }
    if (restore1 && restore3 && +restore1[0] == +restore3[0]) {
      result.push(Constant.FIELD_COMPONENT_3);
    }
    if (restore2 && restore3 && +restore2[0] == +restore3[0]) {
      result.push(Constant.FIELD_COMPONENT_3);
    }
    return result;
  }

  /**
   * get common object
   */
  public static getCommonObject(): Common {
    return <Common>JSON.parse(sessionStorage.getItem(Constant.COMMON_INFORMATION)) ?? new Common();
  }

  /**
   * Resize vertical
   * @param element1 ElementRef
   * @param element2 ElementRef
   * @param e event
   * @param renderer
   */
  public static resizeHeight(element1: ElementRef, element2: ElementRef, e: MouseEvent, renderer: Renderer2) {
    if (e.clientY < 360 || e.clientY > screen.height - 360) {
      e.preventDefault();
      return;
    } else {
      // Change height element1
      renderer.setStyle(element1.nativeElement, 'height', e.clientY + 24 + 'px');
      // Change height element2
      renderer.setStyle(element2.nativeElement, 'height', screen.height - e.clientY - 24 + 'px');
    }
  }

  /**
   * Resize Horizontal
   * @param element1 ElementRef
   * @param element2 ElementRef
   * @param e event
   * @param renderer
   */
  public static resizeWidth(element1: ElementRef, element2: ElementRef, e: MouseEvent, renderer: Renderer2) {
    if (e.clientX < 692 || e.clientX > screen.width - 692) {
      e.preventDefault();
      return;
    } else {
      // Change width element1
      renderer.setStyle(element1.nativeElement, 'width', e.clientX + 'px');
      // Change width element2
      renderer.setStyle(element2.nativeElement, 'width', screen.width - e.clientX + 'px');
    }
  }

  /**
   * reset component state
   * @param fieldComponent
   * @param store
   */
  public static resetComponentState(fieldComponent: FIELD_COMPONENT, store: Store<AppState>) {
    switch (fieldComponent) {
      case FIELD_COMPONENT.UserManagerComponent:
        store.dispatch(new ResetUserManagerStateAction());
        break;
      case FIELD_COMPONENT.ProjectManagerComponent:
        store.dispatch(new ResetProjectManagerStateAction());
        break;
      case FIELD_COMPONENT.DeviceManagerComponent:
        store.dispatch(new ResetDeviceManagerStateAction());
        break;
      case FIELD_COMPONENT.MediaManagerComponent:
        store.dispatch(new ResetMediaManagerStateAction());
        break;
      case FIELD_COMPONENT.IndexWordEditorComponent:
        store.dispatch(new ResetIndexWordStateAction());
        break;
      case FIELD_COMPONENT.MasterListEditorComponent:
        store.dispatch(new ResetMasterListStateAction());
        break;
      case FIELD_COMPONENT.RouteListEditorComponent:
        store.dispatch(new ResetRouteListStateAction());
        break;
      case FIELD_COMPONENT.LcdLayoutEditorComponent:
        store.dispatch(new ResetLCDEditorStateAction());
        break;
      case FIELD_COMPONENT.LedLayoutEditorComponent:
        store.dispatch(new ResetLEDEditorStateAction());
        break;
      case FIELD_COMPONENT.DestinationSignEditorComponent:
        store.dispatch(new ResetDestinationSignEditorStateAction());
        break;
      case FIELD_COMPONENT.BusInformationDisplayEditorComponent:
        store.dispatch(new ResetBusInfoDisplayEditorStateAction());
        break;
      case FIELD_COMPONENT.DigitalSignageContentEditorComponent:
        store.dispatch(new ResetDigitalSignageEditorStateAction());
        break;
      case FIELD_COMPONENT.DeliveryManagerComponent:
        store.dispatch(new ResetDeliveryManagerStateAction());
        break;
      case FIELD_COMPONENT.DashboardComponent:
        store.dispatch(new ResetDashboardStateAction());
        break;
      case FIELD_COMPONENT.StationDisplayEditorComponent:
        store.dispatch(new ResetStationDisplayEditorAction());
        break;
      case FIELD_COMPONENT.SignageDisplayEditorComponent:
        store.dispatch(new ResetSignageDisplayEditorAction());
        break;
      case FIELD_COMPONENT.ExternalContentManagerComponent:
        store.dispatch(new ResetExternalContentManagerAction());
        store.dispatch(new ResetNewsContentTabStateAction());
        store.dispatch(new ResetWeatherContentTabStateAction());
        store.dispatch(new ResetOpenWeatherContentTabStateAction());
        break;
      case FIELD_COMPONENT.TimetableEditorComponent:
        store.dispatch(new ResetTimetableEditorAction());
        break;
      case FIELD_COMPONENT.TimetableOperationManagerComponent:
        store.dispatch(new ResetTimetableOperationManagerStateAction());
        break;
      case FIELD_COMPONENT.SimpleSignageEditorComponent:
        store.dispatch(new ResetSimpleSignageEditorStateAction());
        store.dispatch(new ResetMiniMediaStateAction());
        break;
      default:
        break;
    }
  }
  /**
   * handle scroll menu Software when zoom in and out screen
   * @param screen
   * @param menuSoftware
   */
  public static handleScrollMenu(screen: HTMLDivElement, menuSoftware: HTMLDivElement) {
    if (
      Constant.HEIGHT_SOFTWARE > screen.offsetHeight ||
      (Constant.HEIGHT_SOFTWARE + 10 > screen.offsetHeight && Constant.HEIGHT_SOFTWARE < screen.offsetHeight)
    ) {
      menuSoftware.style.height = +screen.offsetHeight - 10 + 'px';
    } else {
      menuSoftware.style.height = 'auto';
    }
  }
  /**
   * handle  menu  when resize screen
   */
  public static handlingDisplayMenu(widthMenu: number, dataService: DataService, nameDisplay: string) {
    if (nameDisplay == MODULE_NAME[FIELD_COMPONENT.DeliveryManagerComponent]) {
      dataService.sendData(['checkEmergency', widthMenu >= Constant.WIDTH_CHECK_EMERGENCY]);
      dataService.sendData(['checkSetting', widthMenu >= Constant.WIDTH_CHECK_SETTING]);
      dataService.sendData(['checkJob', widthMenu >= Constant.WIDTH_CHECK_JOB]);
      dataService.sendData(['checkDelivery', widthMenu >= Constant.WIDTH_CHECK_DELIVERY]);
    }

    if (nameDisplay == MODULE_NAME[FIELD_COMPONENT.TimetableOperationManagerComponent]) {
      dataService.sendData([
        `${MODULE_NAME[FIELD_COMPONENT.TimetableOperationManagerComponent]}:checkDisplay`,
        widthMenu >= Constant.WIDTH_CHECK_PUBLISH_TIMETABLE
      ]);
      dataService.sendData([
        `${MODULE_NAME[FIELD_COMPONENT.TimetableOperationManagerComponent]}:checkEmergency`,
        widthMenu >= Constant.WIDTH_CHECK_PUBLISH_SIGNAGE
      ]);
      dataService.sendData([
        `${MODULE_NAME[FIELD_COMPONENT.TimetableOperationManagerComponent]}:checkMessage`,
        widthMenu >= Constant.WIDTH_CHECK_SETTING_SIGNAGE
      ]);
    }

    if (nameDisplay == MODULE_NAME[FIELD_COMPONENT.SignageDisplayEditorComponent]) {
      dataService.sendData(['checkPublishSignage', widthMenu >= Constant.WIDTH_CHECK_PUBLISH_SIGNAGE]);
      dataService.sendData(['checkSettingSignage', widthMenu >= Constant.WIDTH_CHECK_SETTING_SIGNAGE]);
      dataService.sendData(['checkDisplaySignage', widthMenu >= Constant.WIDTH_CHECK_DISPLAY_SIGNAGE]);
    }

    if (nameDisplay == MODULE_NAME[FIELD_COMPONENT.BusInformationDisplayEditorComponent]) {
      dataService.sendData(['checkPublishOnBus', widthMenu >= Constant.WIDTH_CHECK_PUBLISH_ON_BUS]);
      dataService.sendData(['checkDisplayOnBus', widthMenu >= Constant.WIDTH_CHECK_DISPLAY_SIGNAGE]);
    }

    if (nameDisplay == MODULE_NAME[FIELD_COMPONENT.StationDisplayEditorComponent]) {
      dataService.sendData(['checkPublishStation', widthMenu >= Constant.WIDTH_CHECK_PUBLISH_ON_BUS]);
      dataService.sendData(['checkDisplayStation', widthMenu >= Constant.WIDTH_CHECK_DISPLAY_SIGNAGE]);
    }

    if (nameDisplay == MODULE_NAME[FIELD_COMPONENT.DigitalSignageContentEditorComponent]) {
      dataService.sendData(['checkPublishDigital', widthMenu >= Constant.WIDTH_CHECK_DISPLAY_SIGNAGE]);
    }

    if (nameDisplay == MODULE_NAME[FIELD_COMPONENT.TimetableEditorComponent]) {
      dataService.sendData(['checkDeliveryTimetable', widthMenu >= Constant.WIDTH_CHECK_PUBLISH_SIGNAGE]);
      dataService.sendData(['checkSettingTimetable', widthMenu >= Constant.WIDTH_CHECK_SETTING_SIGNAGE]);
      dataService.sendData(['checkDisplayTimetable', widthMenu >= Constant.WIDTH_CHECK_DISPLAY_SIGNAGE]);
    }

    if (nameDisplay == MODULE_NAME[FIELD_COMPONENT.ScheduleMergeComponent]) {
      dataService.sendData(['checkMergeSchedule', widthMenu >= Constant.WIDTH_CHECK_MERGE_SCHEDULE]);
      dataService.sendData(['checkUpdateDataScheduleMerge', widthMenu >= Constant.WIDTH_CHECK_UPDATE_DATA_SCHEDULE_MERGE]);
      dataService.sendData(['checkCreateDailyScheduleMerge', widthMenu >= Constant.WIDTH_CHECK_CREATE_DAILY_SCHEDULE]);
    }
    if (nameDisplay == MODULE_NAME[FIELD_COMPONENT.TicketEditorComponent]) {
      dataService.sendData(['Display', widthMenu >= Constant.WIDTH_CHECK_DISPLAYTICKETEDITOR]);
      dataService.sendData(['CheckDelivery', widthMenu >= Constant.WIDTH_CHECK_DELIVERY_TICKETEDITOR]);
      dataService.sendData(['CheckSetting', widthMenu >= Constant.WIDTH_CHECK_SETTING_TICKETEDITOR]);
    }

    if (nameDisplay == MODULE_NAME[FIELD_COMPONENT.ScheduleRegistrationComponent]) {
      dataService.sendData(['checkRegister', widthMenu >= Constant.WIDTH_CHECK_REGISTER]);
    }

    if (nameDisplay == MODULE_NAME[FIELD_COMPONENT.ScheduleOperationManagerComponent]) {
      dataService.sendData([
        `${MODULE_NAME[FIELD_COMPONENT.ScheduleOperationManagerComponent]}:checkMessage`,
        widthMenu >= Constant.WIDTH_CHECK_MESSAGE
      ]);
      dataService.sendData([
        `${MODULE_NAME[FIELD_COMPONENT.ScheduleOperationManagerComponent]}:checkEmergency`,
        widthMenu >= Constant.WIDTH_CHECK_EMERGENCY_SCHEDULE
      ]);
      dataService.sendData([
        `${MODULE_NAME[FIELD_COMPONENT.ScheduleOperationManagerComponent]}:checkUpdateData`,
        widthMenu >= Constant.WIDTH_CHECK_UPDATE_DATA
      ]);
      dataService.sendData([
        `${MODULE_NAME[FIELD_COMPONENT.ScheduleOperationManagerComponent]}:checkNetWork`,
        widthMenu >= Constant.WIDTH_CHECK_NETWORK
      ]);
    }

    if (nameDisplay == MODULE_NAME[FIELD_COMPONENT.AnnouncementManagerComponent]) {
      dataService.sendData([
        `${MODULE_NAME[FIELD_COMPONENT.AnnouncementManagerComponent]}:checkDelivery`,
        widthMenu >= Constant.WIDTH_CHECK_DELIVERY_ANNOUNCEMENT
      ]);
      dataService.sendData([
        `${MODULE_NAME[FIELD_COMPONENT.AnnouncementManagerComponent]}:checkNetWork`,
        widthMenu >= Constant.WIDTH_CHECK_NETWORK_ANNOUNCEMENT
      ]);
    }
  }

  /**
   * check change data before leave
   *
   * @param componentRef
   * @returns
   */
  public static isChangeDataBeforeLeave(componentRef: ComponentRef<any>) {
    return (
      componentRef?.instance.isChangedData ||
      componentRef?.instance?.isChangedNewsTab ||
      componentRef?.instance?.isChangedWeatherTab ||
      componentRef?.instance?.isChangedOpenWeatherTab ||
      componentRef?.instance?.dynamicMessages?.some(dynamic => dynamic.isEdit) ||
      componentRef?.instance?.mediaFilesDroppedOperation?.length ||
      componentRef?.instance?.playlistSelected?.isEdit ||
      componentRef?.instance?.folderSelected?.isEdit ||
      componentRef?.instance?.isChangeMediaOfSequence ||
      componentRef?.instance?.isShowFreeArea ||
      componentRef?.instance?.isEditDataRoute ||
      componentRef?.instance?.isEditDataBusStop ||
      componentRef?.instance?.isChangeBusStopChecked ||
      componentRef?.instance?.isChangedDataMultipleTimetables ||
      componentRef?.instance?.isEditCalendar ||
      componentRef?.instance?.isChangeAudioOfSequence ||
      componentRef?.instance?.isChangeCommonRegistration ||
      componentRef?.instance?.isChangeSpecificRegistration ||
      (componentRef?.instance?.listAppOutPut && componentRef?.instance?.listAppOutPut?.filter(e => !e.appId)?.length > 0)
    );
  }

  /**
   * show error dialog
   *
   * @param errorMessage
   * @param callback
   */
  public static showErrorDialog(dialogService: DialogService, translateService: TranslateService, errorMessage: string): void {
    dialogService.showDialog(DialogMessageComponent, { data: { title: translateService.instant('login.error'), text: errorMessage } });
  }

  /**
   * get privilege view
   *
   * @param privileges
   * @param userRoleId
   * @returns
   */
  public static getPrivilegeViews(privileges: Privilege[], userRoleId: number): Privilege[] {
    return privileges.filter(privilege =>
      userRoleId == UserRoleEnum.ADMINISTRATOR
        ? privilege.isAdminAllowed
        : userRoleId == UserRoleEnum.MANAGER
        ? privilege.isManagerAllowed
        : privilege.isUserAllowed
    );
  }

  /**
   * check valid language on url
   * @param language
   */
  public static checkValidLanguageOnUrl(language: string): boolean {
    return language.toLowerCase() == Constant.EN_LANGUAGE || language.toLowerCase() == Constant.JP_LANGUAGE;
  }

  /**
   * handle fonts
   * @param fonts
   */
  public static async handleFonts(fonts: any[]): Promise<any> {
    if (!fonts || fonts.length == 0) {
      return;
    }
    let fontFaces = document.getElementById('font-collection');
    while (fontFaces != null) {
      fontFaces.remove();
      fontFaces = document.getElementById('font-collection');
    }
    fontFaces = document.createElement('style');
    fontFaces.id = 'font-collection';
    fonts.forEach(font => {
      fontFaces.appendChild(
        document.createTextNode(`@font-face {font-family: ${font['name']};src: url('${font['url']}') format('truetype');}`)
      );
    });
    document.head.appendChild(fontFaces);
    return Promise.all(
      fonts.map((font, index) => {
        let fontFace = new FontFace(font['name'], `url(${font['url']})`);
        return new Promise(resolve => {
          fontFace.load().then(
            () => {
              // font loaded successfully!
              console.log(`loaded font ${index} done`);
              resolve({ load: Constant.FONT_LOADED });
            },
            err => {
              console.error(err);
              resolve({ load: Constant.FONT_LOADED, fail: font['name'] });
            }
          );
        });
      })
    );
  }

  /**
   * handle error user not exist (show msg error and go to login page)
   * @param dialogService
   * @param store
   * @param translateService
   */
  public static handleErrorUserNotExists(dialogService: any, store: any, translateService: any): void {
    // show error message
    dialogService.showDialog(
      DialogMessageComponent,
      {
        data: {
          title: translateService.instant('dialog-message.error-title'),
          text: translateService.instant('dialog-message.user-not-exists')
        }
      },
      result => {
        // clear sessionStorage
        sessionStorage.clear();
        store.dispatch(
          new SaveMainStateAction({
            common: undefined
          })
        );
        store.dispatch(new ResetSortFilterStateAction());
        store.dispatch(new ResetSortFilterUserManagerStateAction());
        store.dispatch(new ResetSortFilterLCDLayoutEditorStateAction());
        store.dispatch(new ResetSortFilterLEDLayoutEditorStateAction());
        // reload
        window.location.reload();
      }
    );
  }
}
